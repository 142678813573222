@import "variables";
@import "framework/framework";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
	font-family: 'Andale Mono';
	src: url('../../dist/font/AndaleMono.woff2') format('woff2'),
		url('../../dist/font/AndaleMono.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Andale Mono';
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

h1,
h2,
h3,
h4,
h5,
ul,
ol,
p {
	margin: 0;
}

p {
	font-size: 16px;
	line-height: 1.4;
}

.hide {
	display: none;
}

.mobile {
	@include tablet-up {
		display: none !important;
	}
}

.desktop {
	@include tablet-down {
		display: none !important;
	}
}

.socials {
	@include flex(row, center, center);
	gap: 20px;

	a {
		font-size: 20px;
	}
}

.split {
	@include flex(column, initial, initial);

	.side {
		flex: 1;
	}

	@include tablet-up {
		@include flex(row, initial, initial);
	}
}

.has-bg {
	position: relative;

	.bg {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;

		@include phone-down {
			padding-top: rems(40);
		}

		&.bg-music {
			@include phone-down {
				object-position: 25% 50%;
			}
		}
	}

	.content {
		position: relative;
	}
}

.social {
	@include flex(row, center, center);
	gap: 10px;

	a {
		width: 40px;
		height: 40px;
		border-radius: 50%;

		@include hover-focus {
			opacity: .7;
		}
	}

	@include tablet-up {
		a {
			width: 50px;
			height: 50px;
		}
	}
}

.section-spacer {
	height: rem(60);
	width: 100%;
	background-image: url('../img/reskin-2/texture.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: $blue;
}

.heading {
	text-transform: uppercase;
	font-weight: 400;
	border: solid 5px transparent;
	text-align: center;
	display: block;
	max-width: max-content;
	margin: 0 auto 40px;
	letter-spacing: 8px;
	padding: 16px 40px;
	font-size: 30px;

	&.black {
		color: $black;
		border-color: $black;
	}

	@include tablet-up {
		margin-bottom: 60px;
	}

	&.white {
		color: $white;
		border-color: $white;
	}

	&.slate {
		color: $slate;
		border-color: $slate;
	}
}

.sources {
	@include flex(row, center, center);
	gap: 10px;

	a {
		@include flex(column, center, center);

		img {
			width: 40px;
			height: 40px;
		}
	}
}

header {

	.hero {
		background-image: url("../img/reskin-2/hero.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		min-height: calc(100vh - 57px);
		display: flex;

		@include tablet-down {
			background-position: 35% 50%;
		}

		@include phone-down {
			background-position: 25% 50%;
		}

		.container {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;

			@include tablet-down {}

			@include phone-down {
				justify-content: space-between;
			}

			button.menu-open {
				border: none;
				border-radius: none;
				background-color: transparent;
				text-decoration: underline;
				font-size: rem(20);
				color: $black;
				margin-top: rem(20);

				@include hover-focus {
					opacity: .7;
				}
			}

			.content {
				margin-bottom: rem(70);
			}
		}
	}

	.menu {
		background-color: $black;
		position: fixed;
		z-index: 999;
		width: 100%;
		height: 100%;
		top: 0;
		left: -100%;
		opacity: 0;
		transition: all .4s;

		&.active {
			opacity: 1;
			left: 0;
		}

		.content {
			height: 100%;
			@include flex(column, center, center);

			.menu-close {
				position: absolute;
				top: 10px;
				right: 10px;
				text-transform: uppercase;
				letter-spacing: 4px;
				text-decoration: underline;
				color: $white;
				padding: 0;
				border: none;
				background-color: transparent;
				font-size: 16px
			}
		}

		.nav {
			@include flex(column, center, center);

			a {
				padding: 20px;
				font-size: 20px;
				text-transform: uppercase;
			}
		}

		@include tablet-up {
			position: relative;
			top: unset;
			left: unset;
			opacity: 1;
			height: unset;

			.container {
				height: unset;

				.nav {
					@include flex(row, center, center);
					gap: 40px;
					padding: 15px 0;

					a {
						padding: 0;
						font-size: 26px;
						border-bottom: solid 1px transparent;
						transition: all .4s;

						@include hover-focus {
							border-bottom-color: white;
						}
					}
				}
			}
		}
	}

}

main {

	>.has-bg {
		>.bg {
			object-position: top;
		}
	}

	#music {
		padding: 40px 0;

		.container {
			.slider-music {
				padding: 0 30px;

				.slick-arrow {
					position: absolute;
					top: calc(50% - 20px);
					font-size: 30px;
					background-color: transparent;
					color: $black;
					border: none;
					padding: 0;
					cursor: pointer;
					z-index: 99;

					&.slick-prev {
						left: 0;
					}

					&.slick-next {
						right: 0;
					}
				}

				.music-item {

					>img {
						box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
					}

					img {
						width: 100%;
						margin-bottom: 20px;
						transition: .3s all;
					}

					a {
						@include hover-focus {
							img {
								filter: brightness(0);
							}
						}
					}

					a.btn {
						border: 2px solid $black;
						display: inline-block;
						padding: .5em 1em;
						text-transform: uppercase;
						font-size: rem(18);
						text-decoration: underline;
						letter-spacing: .1em;
						color: $black;

						@include hover-focus {
							color: $white;
							background-color: $black;
						}
					}
				}
			}
		}

		@include tablet-up {
			padding-top: 80px;

			.container {
				.slider-music {
					.slick-list {
						margin-left: -60px;

						.slick-slide {
							margin-left: 60px;
						}
					}
				}
			}
		}
	}

	#about {
		padding-bottom: 60px;

		.container {
			.wrapper {
				.bio {
					p {
						color: $black;
						font-size: 14px;
						margin-bottom: 30px;

						&:first-child {
							margin-bottom: 0;
						}

						&:nth-child(n + 2) {
							display: none;
						}
					}

					&.all {
						p {
							&:first-child {
								margin-bottom: 30px;
							}

							&:nth-child(n + 2) {
								display: block;
							}
						}
					}
				}

				.bio-all {
					text-decoration: underline;
					color: $black;
					text-transform: uppercase;
					display: block;
					margin: 0 auto;
					max-width: max-content;
					font-size: 30px;
					border: none;
					padding: 0;
					margin-top: 30px;
					background-color: transparent;
					letter-spacing: 8px;
				}
			}
		}

		@include tablet-up {
			padding-top: 40px;

			.container {
				.wrapper {
					max-width: 930px;
					margin: 0 auto;

					.heading {
						margin: 0 auto 40px;
					}

					.bio {
						text-align: center;

						p {
							font-size: 20px;

							&:first-child {
								margin-bottom: 30px;
							}

							&:nth-child(n + 2) {
								display: block;
							}

							&:nth-child(n + 4) {
								display: none;
							}

							&:nth-child(3) {
								margin-bottom: 0;
							}
						}

						&.all {
							p {
								&:nth-child(3) {
									margin-bottom: 30px;
								}

								&:nth-child(n + 4) {
									display: block;
								}
							}
						}
					}

					.bio-all {
						margin: 40px auto;
					}
				}
			}
		}
	}

	.bg-multi-section {
		background-image: url('../img/reskin-2/bg-multi.png');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	#videos {
		.content {
			padding: 80px 0;

			iframe {
				width: 100%;
				aspect-ratio: 6/4;
				margin-bottom: 26px;
			}

			.slider-videos {
				padding: 0 30px;

				.slick-list {
					margin-left: -16px;

					.slick-slide {
						margin-left: 16px;
					}
				}

				.slick-arrow {
					position: absolute;
					top: calc(50% - 15px);
					font-size: 30px;
					background-color: transparent;
					color: $white;
					border: none;
					padding: 0;
					cursor: pointer;
					z-index: 99;

					i {
						font-weight: 400;
					}

					&.slick-prev {
						left: 0;
					}

					&.slick-next {
						right: 0;
					}
				}
			}
		}

		@include tablet-up {
			.content {
				iframe {
					aspect-ratio: 6/3;
				}

				.slider-videos {
					padding: 0 60px;

					.slick-list {
						margin-left: -40px;

						.slick-slide {
							margin-left: 40px;
						}
					}
				}
			}
		}
	}

	#tour {
		padding: 40px 0 80px;

		@include tablet-down {
			background-image: none;
		}

		.seeallbtn {

			text-decoration: underline;
			color: $black;
			text-transform: uppercase;
			display: block;
			margin: 0 auto;
			max-width: max-content;
			font-size: 30px;
			border: none;
			padding: 0;
			margin-top: 30px;
			background-color: transparent;
			letter-spacing: 8px;
		}

		.container {
			.wrapper {
				width: 100%;
				@include flex(column, center, center);
			}

			.heading {
				margin: 0 0 40px;
			}

			#events {
				@include flex(column, center, center);
				gap: 60px;
				text-align: center;

				&:not(.seeall) {
					.event:nth-child(n + 5) {
						display: none;
					}
				}

				.event {
					font-size: 18px;
					text-transform: uppercase;
					color: $black;
					line-height: 1.2;

					.event-date {
						font-size: 24px;
					}

					.event-links {
						margin-top: 20px;

						a {
							font-size: 28px;
							color: $black;
							letter-spacing: 8px;
							text-decoration: underline;
						}
					}
				}
			}
		}

		@include tablet-up {
			padding: 60px 0;
		}
	}

	>.has-bg {
		background-color: $white;

		&:last-child {
			.bg {
				object-position: left bottom;

				@include tablet-down {
					opacity: .5;
				}
			}
		}
	}

	#shop {
		padding: 80px 0;

		.container {
			>a {
				color: $white;
				text-align: center;
				text-transform: uppercase;
				font-size: 30px;
				letter-spacing: 8px;
				display: block;
				text-align: center;
				margin-top: 30px;
				text-decoration: underline;
			}

			.slider-shop {
				.shop-item {
					img {
						width: 100%;
						margin-bottom: 18px;
					}

					a {
						color: $white;
						text-align: center;
						display: block;
						font-size: 18px;
						text-transform: uppercase;
						text-decoration: underline;
						margin-bottom: 30px;
					}
				}
			}
		}

		@include tablet-up {
			.container {
				>a {
					margin-top: 80px;
				}

				.slider-shop {
					@include flex(row, center, center);
					gap: 40px;

					.shop-item {
						flex: 1;
					}
				}
			}
		}
	}

}

footer {

	.wrapper {
		margin: 0 auto;
	}

	.newsletter {
		color: $white;
		margin-bottom: 60px;

		h3 {
			font-size: 30px;
			letter-spacing: 8px;
			text-transform: uppercase;
			margin-bottom: 20px;
			text-transform: uppercase;

		}

		form {
			@include flex(column, initial, initial);
			gap: 20px;

			.form-row {
				@include flex(column, initial, initial);
				text-transform: uppercase;
				gap: 5px;

				label {
					font-size: 20px;
					display: block;
				}

				input {
					padding: 10px;
					border: solid 1px $white;
					background-color: transparent;
					margin-bottom: rem(20);
				}

				button {
					font-size: 30px;
					text-transform: uppercase;
					letter-spacing: 8px;
					text-align: center;
					padding: 0;
					background-color: transparent;
					border: none;
					color: $white;
					text-decoration: underline;
				}
			}
		}
	}

	.social {
		margin-bottom: 40px;
		display: flex;
		justify-content: center;

		a {
			width: 50px;
			height: 50px;
			font-size: 25px;
		}
	}

	.copyright {
		padding: 20px 0 40px;

		p {
			text-align: center;
			color: $white;
			font-size: 16px;

			a {
				color: $white;
			}
		}
	}

	@include tablet-up {
		padding: 100px 0 40px;

		.container {
			.wrapper {
				max-width: 500px;
				margin-left: auto;
			}
		}
	}

}

// #popup {
// 	position: relative;
// 	width: 100%;
// 	max-width: rem(950);
// 	margin: 0 auto;
// 	display: flex;
// 	background-image: url('../img/fabric-texture.jpg');
// 	background-size: 200% auto;
// 	padding: rem(20) 0;

// 	@media screen and (max-width: 800px) {
// 		flex-direction: column;
// 		max-width: rem(500);
// 	}

// 	>div {
// 		flex: 1;
// 		padding: 0 rem(30);

// 		img {
// 			margin: rem(-40) 0;
// 			transform: rotate(-3deg);
// 		}

// 		@media screen and (max-width: 800px) {
// 			&:first-child {
// 				padding-bottom: rem(40);
// 			}
// 		}

// 		&.column {
// 			padding: rem(30) rem(30) 0;

// 			@media screen and (max-width: 800px) {
// 				padding: rem(30) rem(10);
// 			}

// 			display: flex;
// 			flex-direction: column;
// 			justify-content: space-between;
// 			align-items: center;
// 			text-align: center;

// 			p:first-child {
// 				text-transform: uppercase;
// 			}

// 			p:nth-child(2) {
// 				text-transform: uppercase;
// 				font-size: rem(40);
// 			}

// 			&::after {
// 				content: '';
// 			}
// 		}
// 	}

// 	a.btn {
// 		border: 2px solid $white;
// 		display: inline-block;
// 		padding: .5em 1em;
// 		text-transform: uppercase;
// 		font-size: rem(18);
// 		text-decoration: underline;
// 		letter-spacing: .1em;
// 		color: $white;

// 		@include hover-focus {
// 			border-color: $slate;
// 			background-color: $slate;
// 		}
// 	}

// 	.mfp-close {
// 		color: $white;
// 		opacity: 1;

// 		@include hover-focus {
// 			color: $black;
// 		}
// 	}
// }